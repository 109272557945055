import { useMemo } from "react";

import { Input, Card, CardBody } from "reactstrap";

import { TableSearchInput } from "@atoms";

const commonClassName =
  "btn-w text-light table__search table__search-input mb-0";

const PlansTableSearch = ({ form, setSubmitSearch }) => {
  const { control, setValue, watch } = form;
  const { searchInput } = watch();

  const handleClear = (e) => {
    e.preventDefault();
    setValue("searchInput", "");
    setSubmitSearch(true);
  };

  const handleClick = () => {
    setSubmitSearch(true);
  };

  const buttonProps = useMemo(
    () => [
      {
        type: "submit",
        className: `btn-blue ${commonClassName}`,
        value: "Filter",
        onClick: handleClick,
      },
      ...(searchInput
        ? [
            {
              type: "reset",
              className: commonClassName,
              value: "Clear",
              onClick: handleClear,
            },
          ]
        : []),
    ],
    [searchInput]
  );

  return (
    <Card className="pb-2">
      <CardBody className="py-2 px-0 table__search mb-0">
        <TableSearchInput
          name="searchInput"
          control={control}
          className="w-100"
          maxLength={100}
          placeholder="Plan Name or Tax ID"
        />
        {buttonProps?.map((props) => (
          <Input key={props?.value} {...props} />
        ))}
      </CardBody>
    </Card>
  );
};

export default PlansTableSearch;
