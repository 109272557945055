import { useCallback, useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { TableDashboard, Table, Spinner } from "@atoms";
import { pagination } from "@config/conf";
import { getPagination } from "@containers/components/helpers";
import useApiFetch from "@hooks/useApiFetch";
import useCsvExportButton from "@hooks/useCsvExportButton";
import useQueryParams from "@hooks/useQueryParams";

import EnrollmentsTableSearch from "./EnrollmentsTableSearch";

const columns = [
  {
    header: "RE Account",
    id: "reAccount",
    accessorKey: "reAccount",
  },
  {
    header: "Plan Name",
    id: "planName",
    minWidth: 250,
    accessorKey: "planName",
  },
  {
    header: "Participant Name",
    id: "participantName",
    minWidth: 200,
    accessorKey: "participantName",
  },
  {
    header: "Workflow Start",
    id: "workflowStart",
    alignRight: true,
    cell: ({ row }) => (
      <div className="text-right my-1">{row.original?.workflowStart}</div>
    ),
  },
  {
    header: "Status Start",
    id: "statusStart",
    enableSorting: true,
    accessorKey: "statusStart",
    alignRight: true,
    cell: ({ row }) => (
      <div className="text-right mr-3">{row.original?.statusStart}</div>
    ),
  },
  {
    header: "Age",
    id: "age",
    accessorKey: "age",
  },
  {
    header: "Workflow",
    id: "workflow",
    accessorFn: () => "Process Enrollment",
  },
  {
    header: "Status",
    id: "status",
    accessorKey: "status",
  },
  {
    header: "Premium",
    id: "premium",
    alignRight: true,
    cell: ({ row }) => (
      <div className="text-right">{row.original?.premium}</div>
    ),
  },
  {
    header: "SSN",
    id: "ssn",
    accessorKey: "ssn",
  },
];

const Enrollments = () => {
  const { get, isPending } = useApiFetch();
  const { buildQueryParams, getQueryParams } = useQueryParams();

  const {
    search,
    status,
    pageSize: pageSizeQuery,
    page,
    sortStatusStart,
  } = getQueryParams([
    "search",
    "status",
    "pageSize",
    "page",
    "sortStatusStart",
  ]);

  const form = useForm({
    defaultValues: {
      searchAccount: search,
      statusType: status || ["Pending Premium", "Pending Issue"],
    },
  });
  const { getValues } = form;

  const history = useHistory();
  const [enrollmentData, setEnrollmentData] = useState([]);
  const [submitSearch, setSubmitSearch] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { searchAccount, statusType } = getValues();

  const [pageSize, setPageSize] = useState(pageSizeQuery || pagination.length);
  const { offset, pageNumber } = getPagination(pageSize, page);

  const { csvClickRef, csvData, getExport, loadingResponse } =
    useCsvExportButton();

  const orderByConfig = useMemo(() => {
    return {
      orderBy: "StatusDate",
      orderByDirection:
        sortStatusStart === "asc"
          ? "ASC"
          : sortStatusStart === "desc"
            ? "DESC"
            : "",
    };
  }, [sortStatusStart]);

  const getEnrollmentsData = useCallback(async () => {
    const { orderBy, orderByDirection } = orderByConfig;
    const queryParams = buildQueryParams({
      ReAccount: searchAccount,
      Status: statusType,
      Limit: pageSize,
      OffSet: offset,
      OrderBy: orderBy,
      OrderByDirection: orderByDirection,
    });

    const response = await get(
      `enrollment/enrollmentStatusReport?${queryParams}`,
      { shouldUseBaseUrl: true }
    );
    const responseData = response?.length ? response : [];
    setEnrollmentData(responseData);
  }, [orderByConfig, pageSize, offset, submitSearch]);

  const redirectUrl = useMemo(() => {
    const queryPath = buildQueryParams({
      search: searchAccount,
      status: statusType,
      sortStatusStart: sortStatusStart || "asc",
      pageSize,
    });
    return `/enrollments?page=1&${queryPath}`;
  }, [pageSize, searchAccount, sortStatusStart, statusType]);

  useEffect(() => {
    if (submitSearch) {
      history.push(redirectUrl);
    } else {
      if (!orderByConfig.orderByDirection) {
        history.push(redirectUrl);
      }
      getEnrollmentsData();
    }
    setSubmitSearch(false);
    setRefresh(false);
  }, [
    submitSearch,
    refresh,
    getEnrollmentsData,
    orderByConfig.orderByDirection,
  ]);

  const pageProps = {
    total: enrollmentData?.length,
    pageSize,
    setPageSize,
    pageNumber,
  };

  const handleExportCsv = useCallback(() => {
    getExport("enrollment/enrollmentStatusReport", true);
  }, []);

  return (
    <TableDashboard
      pageTitle="Enrollments"
      setRefresh={setRefresh}
      exportCsvProps={{
        fileName: "EnrollmentsLists",
        csvClickRef,
        csvData,
        getCsvExport: handleExportCsv,
        loadingResponse,
      }}
    >
      <Spinner requesting={isPending}>
        <EnrollmentsTableSearch form={form} setSubmiSearch={setSubmitSearch} />
        <Table columns={columns} data={enrollmentData} pageProps={pageProps} />
      </Spinner>
    </TableDashboard>
  );
};

export default Enrollments;
